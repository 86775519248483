import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={styles["footer"]}>
      <div className={styles["footer-icons"]}>
        <a href="https://linktr.ee/zillionairegoatclub" title="Website">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 100 100"
            fill="#fff"
          >
            <path d="m42.25 11.75c-10.133 2.0391-18.836 8.0117-24.469 16.25h14.438c0.74219-2.3164 1.6016-4.4922 2.5625-6.4688 2.0117-4.1289 4.5352-7.5664 7.4688-9.7812zm15.5 0c2.9336 2.2148 5.457 5.6523 7.4688 9.7812 0.96094 1.9766 1.8203 4.1523 2.5625 6.4688h14.438c-5.6328-8.2383-14.336-14.211-24.469-16.25zm-9.75 1.5312c-3.4766 0.95312-6.8828 4.3711-9.625 10-0.69922 1.4375-1.3203 3.0391-1.9062 4.7188h11.531zm4 0v14.719h11.531c-0.58594-1.6797-1.207-3.2812-1.9062-4.7188-2.7422-5.6289-6.1484-9.0469-9.625-10zm-36.594 18.719c-2.5156 4.8281-4.0508 10.254-4.3438 16h17.969c0.13672-5.7109 0.86328-11.129 2.0625-16zm19.812 0c-1.2539 4.7734-2.0078 10.227-2.1562 16h14.938v-16zm16.781 0v16h14.938c-0.14844-5.7734-0.90234-11.227-2.1562-16zm16.906 0c1.1992 4.8711 1.9258 10.293 2.0625 16h17.969c-0.29297-5.7461-1.8281-11.172-4.3438-16zm-57.844 20c0.29297 5.7461 1.8281 11.172 4.3438 16h15.688c-1.2031-4.8711-1.9258-10.281-2.0625-16zm22 0c0.14844 5.7773 0.89844 11.23 2.1562 16h12.781v-16zm18.938 0v16h12.781c1.2578-4.7695 2.0078-10.223 2.1562-16zm18.969 0c-0.13672 5.7188-0.85938 11.129-2.0625 16h15.688c2.5156-4.8281 4.0508-10.254 4.3438-16zm-53.188 20c5.6445 8.2539 14.375 14.223 24.531 16.25-2.957-2.2227-5.5078-5.6562-7.5312-9.8125-0.96094-1.9766-1.8203-4.125-2.5625-6.4375zm18.688 0c0.58594 1.6758 1.207 3.25 1.9062 4.6875 2.7422 5.625 6.1484 9.0703 9.625 10.031v-14.719zm15.531 0v14.719c3.4766-0.96094 6.8828-4.4062 9.625-10.031 0.69922-1.4375 1.3203-3.0117 1.9062-4.6875zm15.781 0c-0.74219 2.3125-1.6016 4.4609-2.5625 6.4375-2.0234 4.1562-4.5742 7.5898-7.5312 9.8125 10.156-2.0273 18.887-7.9961 24.531-16.25z" />
          </svg>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
